/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./landing-page.component";
var styles_LandingPageComponent = [];
var RenderType_LandingPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LandingPageComponent, data: {} });
export { RenderType_LandingPageComponent as RenderType_LandingPageComponent };
export function View_LandingPageComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_LandingPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-landing-page", [], null, null, null, View_LandingPageComponent_0, RenderType_LandingPageComponent)), i0.ɵdid(1, 49152, null, 0, i1.LandingPageComponent, [], null, null)], null, null); }
var LandingPageComponentNgFactory = i0.ɵccf("app-landing-page", i1.LandingPageComponent, View_LandingPageComponent_Host_0, {}, {}, []);
export { LandingPageComponentNgFactory as LandingPageComponentNgFactory };
