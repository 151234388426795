import * as tslib_1 from "tslib";
var FeatureToggle = /** @class */ (function () {
    function FeatureToggle(credentials) {
    }
    FeatureToggle.prototype.initialize = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, true];
            });
        });
    };
    FeatureToggle.prototype.isEnabled = function (featureName, attributes) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, false];
            });
        });
    };
    FeatureToggle.prototype.whenEnabled = function (featureName, callBackFn, attributes) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                callBackFn();
                return [2 /*return*/];
            });
        });
    };
    FeatureToggle.prototype.whenDisabled = function (featureName, callBackFn, attributes) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                callBackFn();
                return [2 /*return*/];
            });
        });
    };
    return FeatureToggle;
}());
export { FeatureToggle };
