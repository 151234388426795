import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpResponse,
  HttpErrorResponse,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter, tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from '../../environments/environment';
import { AuthenticationService } from '../services/authentication.service';

// Use this interceptor to handle correcting our URLs
// As well as expired auth tokens
@Injectable()
export class HttpReqInterceptor implements HttpInterceptor {
  constructor(
      private helper: JwtHelperService,
      private authenticationService: AuthenticationService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let modifiedReq = req.clone({});

    const token = localStorage.getItem('token');
    if ((!token || token === 'undefined') && !localStorage.getItem('refresh_token')) {
      localStorage.clear();
      this.authenticationService.logout();
      return;
    }

    if (
      !req.url.includes('with_refresh_token') &&
      !req.url.includes('renew_with_refresh_token')
    ) {
      const isExpired = (token && token !== 'undefined') ? this.helper.isTokenExpired(token) : true;
      if (isExpired) {
        const request = new XMLHttpRequest();

        request.open('POST', `${environment.apiUrl}/renew_with_refresh_token`, false);
        request.setRequestHeader('Content-Type', 'application/json');
        request.setRequestHeader('Authorization', localStorage.refresh_token);
        request.send(null);

        let newToken;
        if (request.status === 200 && request.responseText) {
          newToken = request.responseText;
        } else {
          localStorage.clear();
          this.authenticationService.logout();
        }

        localStorage.setItem('token', newToken);
      }

      modifiedReq = modifiedReq.clone({
        setHeaders: {
          Authorization: localStorage.getItem('token'),
        },
      });
    }

    return next.handle(modifiedReq).pipe(tap(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {

        }
      },
      (err: any) => {
        if (err.status === 401) {
          localStorage.removeItem('token');
          this.authenticationService.logout();
        }
      },
    ));
  }
}
