import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FeatureService {

  constructor(private http: HttpClient) { }

  getFeatures() {
    return this.http.get(`${environment.apiUrl}features`);
  }
}
