// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const baseUrl = 'https://pftest.risepeople.com/';
export const environment = {
  baseUrl,
  isDev: false,
  production: false,
  apiUrl: 'https://gateway-test.risepeople.com/',
  authUrl: `${baseUrl}auth/`,
  hubUrl: 'people/',
  timeOffUrl: 'time-off/',
  timeTrackUrl: 'timetrack/',
  knowledgeUrl: 'httpss://support.risepeople.com/portal/kb',
  notesUrl: 'https://support.risepeople.com/article/659-time-off-team-hub',
  benefitsUrl: 'benefits/',
  onboardingUrl: 'onboarding/',
  schedulingUrl: 'scheduling/',
  performanceManagementUrl: '',
  advisorPortalUrl: `${baseUrl}advisor-portal`,
  carrierPortalUrl: `${baseUrl}carrier-portal`,
  helpCentreUrl: 'https://support.risepeople.com/portal/home',
  AIRBRAKE_PROJECT_ID: 258383,
  AIRBRAKE_PROJECT_KEY: '9ccf1dc3673f62e4709e66cc60b4226b',
  AIRBRAKE_ENVIRONMENT_TYPE: 'Testing',
  SPLIT_API_KEY: 'e8vnncajoo2rc2u342gloaugm94093a6e2im',
  SPLIT_API_FEATURES: { 'UIU-583_New-UI': 'on' },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
