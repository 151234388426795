import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { throwError } from 'rxjs';
import { timeout, map, finalize, catchError } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { FeatureService } from './services/feature.service';
import { FeatureToggle } from './FeatureToggle';
import { AuthenticationService } from './services/authentication.service';
declare const require: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  private features = {
    sponsor: 'sponsor-portal',
    hr: 'hris',
    benefits: 'benefits',
    scheduling: 'scheduling-time-attendance',
  };
  private advisorRoles: string[] = ['advisor'];
  private sponsorRoles: string[] = ['organization_owner'];
  private carrierRoles: string[] = ['provider_sun_life_support'];
  constructor(
    private router: Router,
    private featureService: FeatureService,
    private translate: TranslateService,
    private jwtHelperService: JwtHelperService,
    private ftClient: FeatureToggle,
    private authenticationService: AuthenticationService,
  ) {
    const decodedToken = this.getDecodedToken();
    this.setupLanguage(decodedToken);
    this.redirect(decodedToken);
  }

  getDecodedToken() {

    let decodedToken;
    try {
      decodedToken = this.jwtHelperService.decodeToken(localStorage.getItem('token'));
    } catch (ex) {

    }

    return decodedToken;
  }

  setupLanguage(decodedToken) {
    this.translate.setTranslation('fr', require('../assets/i18n/fr.json'));
    this.translate.setTranslation('en', require('../assets/i18n/en.json'));
    this.translate.setDefaultLang('en');

    if (decodedToken && decodedToken.uta && decodedToken.uta.language) {
      this.translate.use(decodedToken.uta.language);
    } else {
      this.translate.use(this.translate.getBrowserLang());
    }
  }

  async redirect(decodedToken) {
    let organizationRoles = [];
    if (decodedToken && decodedToken.uta && decodedToken.uta.organization_roles) {
      organizationRoles = decodedToken.uta.organization_roles;
    }

    if (organizationRoles.length) {
      if (this.advisorRoles.some((role) => organizationRoles.includes(role))) {
        location.href = environment.advisorPortalUrl;
        return;
      }

      if (this.carrierRoles.some((role) => organizationRoles.includes(role))) {
        location.href = environment.carrierPortalUrl;
        return;
      }
    }

    this.featureService.getFeatures().pipe(
      timeout(10000),
      map((response: any) => {
        return response.data.map((r) => {
          return r.attributes.name;
        });
      }),
      catchError((error: any) => {
        if (error && error.status) {
          if (error.status === 401) {
            this.authenticationService.logout();
          } else {
            this.router.navigate(['404'], { skipLocationChange: true });
          }
        }

        return throwError(error || 'Timeout Exception');
      }),
    ).subscribe(
      (features) => {
        try {
          localStorage.setItem('_orgFeatureFlags', JSON.stringify(features));
        } catch (ex) {
        }
        switch (true) {
          case features.includes(this.features.sponsor) && this.sponsorRoles.some((role) => organizationRoles.includes(role)):
            location.href = environment.advisorPortalUrl;
            break;
          case features.includes(this.features.hr):
            location.href =
              `${environment.baseUrl}app/#/${environment.hubUrl}`;
            break;
          case features.includes(this.features.scheduling):
            location.href =
              `${environment.baseUrl}${environment.schedulingUrl}`;
            break;
          case features.includes(this.features.benefits):
            location.href =
              `${environment.baseUrl}app/#/${environment.benefitsUrl}`;
            break;
          default:
            this.router.navigate(['404'], { skipLocationChange: true });
        }
      },
      (error: any) => {
        // Catching error, as already handled earlier.
      },
    );
  }
}
