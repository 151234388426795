import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { throwError } from 'rxjs';
import { timeout, map, catchError } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { FeatureService } from './services/feature.service';
import { FeatureToggle } from './FeatureToggle';
import { AuthenticationService } from './services/authentication.service';
var AppComponent = /** @class */ (function () {
    function AppComponent(router, featureService, translate, jwtHelperService, ftClient, authenticationService) {
        this.router = router;
        this.featureService = featureService;
        this.translate = translate;
        this.jwtHelperService = jwtHelperService;
        this.ftClient = ftClient;
        this.authenticationService = authenticationService;
        this.features = {
            sponsor: 'sponsor-portal',
            hr: 'hris',
            benefits: 'benefits',
            scheduling: 'scheduling-time-attendance',
        };
        this.advisorRoles = ['advisor'];
        this.sponsorRoles = ['organization_owner'];
        this.carrierRoles = ['provider_sun_life_support'];
        var decodedToken = this.getDecodedToken();
        this.setupLanguage(decodedToken);
        this.redirect(decodedToken);
    }
    AppComponent.prototype.getDecodedToken = function () {
        var decodedToken;
        try {
            decodedToken = this.jwtHelperService.decodeToken(localStorage.getItem('token'));
        }
        catch (ex) {
        }
        return decodedToken;
    };
    AppComponent.prototype.setupLanguage = function (decodedToken) {
        this.translate.setTranslation('fr', require('../assets/i18n/fr.json'));
        this.translate.setTranslation('en', require('../assets/i18n/en.json'));
        this.translate.setDefaultLang('en');
        if (decodedToken && decodedToken.uta && decodedToken.uta.language) {
            this.translate.use(decodedToken.uta.language);
        }
        else {
            this.translate.use(this.translate.getBrowserLang());
        }
    };
    AppComponent.prototype.redirect = function (decodedToken) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var organizationRoles;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                organizationRoles = [];
                if (decodedToken && decodedToken.uta && decodedToken.uta.organization_roles) {
                    organizationRoles = decodedToken.uta.organization_roles;
                }
                if (organizationRoles.length) {
                    if (this.advisorRoles.some(function (role) { return organizationRoles.includes(role); })) {
                        location.href = environment.advisorPortalUrl;
                        return [2 /*return*/];
                    }
                    if (this.carrierRoles.some(function (role) { return organizationRoles.includes(role); })) {
                        location.href = environment.carrierPortalUrl;
                        return [2 /*return*/];
                    }
                }
                this.featureService.getFeatures().pipe(timeout(10000), map(function (response) {
                    return response.data.map(function (r) {
                        return r.attributes.name;
                    });
                }), catchError(function (error) {
                    if (error && error.status) {
                        if (error.status === 401) {
                            _this.authenticationService.logout();
                        }
                        else {
                            _this.router.navigate(['404'], { skipLocationChange: true });
                        }
                    }
                    return throwError(error || 'Timeout Exception');
                })).subscribe(function (features) {
                    try {
                        localStorage.setItem('_orgFeatureFlags', JSON.stringify(features));
                    }
                    catch (ex) {
                    }
                    switch (true) {
                        case features.includes(_this.features.sponsor) && _this.sponsorRoles.some(function (role) { return organizationRoles.includes(role); }):
                            location.href = environment.advisorPortalUrl;
                            break;
                        case features.includes(_this.features.hr):
                            location.href =
                                environment.baseUrl + "app/#/" + environment.hubUrl;
                            break;
                        case features.includes(_this.features.scheduling):
                            location.href =
                                "" + environment.baseUrl + environment.schedulingUrl;
                            break;
                        case features.includes(_this.features.benefits):
                            location.href =
                                environment.baseUrl + "app/#/" + environment.benefitsUrl;
                            break;
                        default:
                            _this.router.navigate(['404'], { skipLocationChange: true });
                    }
                }, function (error) {
                    // Catching error, as already handled earlier.
                });
                return [2 /*return*/];
            });
        });
    };
    return AppComponent;
}());
export { AppComponent };
