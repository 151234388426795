import {
  ClientCredentialInterface,
} from '@risepeopleinc/feature_toggle_client_typescript/dist/types/interfaces/clientCredentialInterface';
import {
  FeatureToggleInterface,
} from '@risepeopleinc/feature_toggle_client_typescript/dist/types/interfaces/featureToggleInterface';
import {
  SplitAttributeInterface,
} from '@risepeopleinc/feature_toggle_client_typescript/dist/types/interfaces/SplitAttributeInterface';

export class FeatureToggle {

  client: FeatureToggleInterface;

  private clientFactory: any;

  constructor(credentials: ClientCredentialInterface) {

  }

  async initialize(): Promise<boolean> {
    return true;
  }

  async isEnabled(featureName: string, attributes?: SplitAttributeInterface): Promise<boolean> {
    return false;
  }

  async whenEnabled(
    featureName: string,
    callBackFn: () => void,
    attributes?: SplitAttributeInterface,
  ): Promise<void> {
    callBackFn();
  }

  async whenDisabled(
    featureName: string,
    callBackFn: () => void,
    attributes?: SplitAttributeInterface,
  ): Promise<void> {
    callBackFn();
  }
}
