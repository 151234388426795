import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FeatureService = /** @class */ (function () {
    function FeatureService(http) {
        this.http = http;
    }
    FeatureService.prototype.getFeatures = function () {
        return this.http.get(environment.apiUrl + "features");
    };
    FeatureService.ngInjectableDef = i0.defineInjectable({ factory: function FeatureService_Factory() { return new FeatureService(i0.inject(i1.HttpClient)); }, token: FeatureService, providedIn: "root" });
    return FeatureService;
}());
export { FeatureService };
