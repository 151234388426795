<div ngClass="full-screen">
  <div ngClass="container">
    <img src="/assets/404.svg" />
    <p ngClass="heading">
      <span [innerHTML]="'NOT_FOUND_PAGE.HEADING' | translate"></span>
    </p>
    <p ngClass="text">
      <span [innerHTML]="'NOT_FOUND_PAGE.REFRESH_DESCRIPTION' | translate:{ url: helpCentreUrl }">
      </span>
    </p>
  </div>
</div>
