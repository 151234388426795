import * as tslib_1 from "tslib";
import { environment } from '../../environments/environment';
import { FeatureToggle } from '../FeatureToggle';
import * as i0 from "@angular/core";
import * as i1 from "../FeatureToggle";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(ftClient) {
        this.ftClient = ftClient;
    }
    AuthenticationService.prototype.isNewAuthenticationEnabled = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.ftClient.isEnabled('UIU-1013-launchpad-new-login-app')];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    AuthenticationService.prototype.getLogoutUrl = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var isEnabled;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.logoutUrl = environment.authUrl + "#/logout";
                        return [4 /*yield*/, this.isNewAuthenticationEnabled()];
                    case 1:
                        isEnabled = _a.sent();
                        if (isEnabled) {
                            this.logoutUrl = '/app/#/auth/logout';
                        }
                        return [2 /*return*/, this.logoutUrl];
                }
            });
        });
    };
    AuthenticationService.prototype.logout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = location;
                        return [4 /*yield*/, this.getLogoutUrl()];
                    case 1:
                        _a.href = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthenticationService.ngInjectableDef = i0.defineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.inject(i1.FeatureToggle)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
