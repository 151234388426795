import { ErrorHandler } from '@angular/core';
import { Notifier } from '@airbrake/browser';
import { environment } from '../../environments/environment';

export class AirbrakeErrorHandler implements ErrorHandler {
  airbrake: Notifier;

  constructor() {
    if (environment.AIRBRAKE_PROJECT_KEY !== '' && environment.AIRBRAKE_PROJECT_ID !== null) {
      this.airbrake = new Notifier({
        projectId: environment.AIRBRAKE_PROJECT_ID,
        projectKey: environment.AIRBRAKE_PROJECT_KEY,
        environment: environment.AIRBRAKE_ENVIRONMENT_TYPE
      });
    }
  }

  handleError(error: any): void {
    if (environment.AIRBRAKE_PROJECT_KEY !== '' && environment.AIRBRAKE_PROJECT_ID !== null) {
      this.airbrake.notify(error);
    }
  }
}
