import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { FeatureToggle } from '../FeatureToggle';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private logoutUrl: string;

  constructor(private ftClient: FeatureToggle) {}

  private async isNewAuthenticationEnabled() {
    return await this.ftClient.isEnabled('UIU-1013-launchpad-new-login-app');
  }

  private async getLogoutUrl() {
    this.logoutUrl = `${environment.authUrl}#/logout`;
    const isEnabled = await this.isNewAuthenticationEnabled();
    if (isEnabled) {
      this.logoutUrl = '/app/#/auth/logout';
    }
    return this.logoutUrl;
  }

  async logout() {
    location.href = await this.getLogoutUrl();
  }
}
