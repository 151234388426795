import { FeatureToggleClient } from '@risepeopleinc/feature_toggle_client_typescript';
import { environment } from 'src/environments/environment';
export function jwtTokenGetter() {
    return localStorage.token;
}
var ɵ0 = { hasBackdrop: false }, ɵ1 = function () {
    var fTClient = new FeatureToggleClient({
        apiKey: environment.SPLIT_API_KEY,
        features: environment.SPLIT_API_FEATURES,
    });
    return fTClient;
}, ɵ2 = function (ftClient) {
    return function () { return Promise.all([ftClient.initialize()]); };
};
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2 };
