import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';

import { HttpReqInterceptor } from './interceptors/http-req-interceptor';
import { IsAuthenticatedGuard } from './guards/is-authenticated/is-authenticated.guard';
import { FeatureService } from './services/feature.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

import { AirbrakeErrorHandler } from './helpers/airbrake-error-handler';
import { FeatureToggleClient } from '@risepeopleinc/feature_toggle_client_typescript';
import { environment } from 'src/environments/environment';
import { FeatureToggle } from './FeatureToggle';

export function jwtTokenGetter() {
  return (localStorage as any).token;
}

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatDialogModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
      },
    }),
    TranslateModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpReqInterceptor,
      multi: true,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: false },
    },
    {
      provide: ErrorHandler,
      useClass: AirbrakeErrorHandler,
    },
    {
      provide: FeatureToggle,
      useFactory: () => {
        const fTClient: any = new FeatureToggleClient({
          apiKey: environment.SPLIT_API_KEY,
          features: environment.SPLIT_API_FEATURES,
        });
        return fTClient;
      },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (ftClient: FeatureToggleClient) => {
        return () => Promise.all([ftClient.initialize()]);
      },
      deps: [FeatureToggle],
      multi: true,
    },
    FeatureService,
    IsAuthenticatedGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
